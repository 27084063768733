import './OwnerSearchPanel.scss'
import {useDispatch, useSelector} from "react-redux";
import {
    setAccountType,
    setCompanyAddressUSPSVerified,
    setPrimaryContactHasEmail,
    setPrimaryContactHasPhone,
    setPortfolioValueMin,
    setPortfolioValueMax,
    setTotalPropertiesOwnedMin,
    setTotalPropertiesOwnedMax,
    selectAccountType,
    selectCompanyAddressUSPSVerified,
    selectPrimaryContactHasEmail,
    selectPrimaryContactHasPhone,
    selectPortfolioValueMin,
    selectPortfolioValueMax,
    selectTotalPropertiesOwnedMin,
    selectTotalPropertiesOwnedMax,
    setIdiCoreStatus,
    selectIdiCoreStatus,
    selectIdiCoreStatusList,
    setIdiCoreStatusList,
    selectIncludeNonPrimaryOwners,
    setIncludeNonPrimaryOwners
} from "../../../../main/store/slices/leadMakerSearchSlice";
import NumericRange from "../SearchInputs/NumericRange";
import Picklist from "../SearchInputs/Picklist";
import {getAccountTypes, getIdiCoreStatusList} from "../../../../main/API/leadMakerSearchApi";
import {useEffect, useState} from "react";
import ObjectPicklist from "../SearchInputs/ObjectPicklist";
import SearchCheckbox from '../SearchInputs/SearchCheckbox';

const OwnerSearchPanel = () => {
    const dispatch = useDispatch();

    const {
        accountType,
        companyAddressUSPSVerified,
        primaryContactHasEmail,
        primaryContactHasPhone,
        idiCoreStatusList,
        idiCoreStatus,
        portfolioValueMin,
        portfolioValueMax,
        totalPropertiesOwnedMin,
        totalPropertiesOwnedMax,
        includeNonPrimaryOwners
    } = useSelector((state) => ({
        accountType: selectAccountType(state),
        companyAddressUSPSVerified: selectCompanyAddressUSPSVerified(state),
        primaryContactHasEmail: selectPrimaryContactHasEmail(state),
        primaryContactHasPhone: selectPrimaryContactHasPhone(state),
        idiCoreStatusList: selectIdiCoreStatusList(state),
        idiCoreStatus: selectIdiCoreStatus(state),
        portfolioValueMin: selectPortfolioValueMin(state),
        portfolioValueMax: selectPortfolioValueMax(state),
        totalPropertiesOwnedMin: selectTotalPropertiesOwnedMin(state),
        totalPropertiesOwnedMax: selectTotalPropertiesOwnedMax(state),
        includeNonPrimaryOwners: selectIncludeNonPrimaryOwners(state)
    }))

    // Options for account types, set to current value as only option on initial render
    const [accountTypeOptions, setAccountTypeOptions] = useState([accountType]);

    // Fetch the options for account types
    useEffect(() => {
        let ignore = false;
        const fetchAccountTypes = async () => {
            const response = await getAccountTypes();
            if (response.status === 200 && !ignore) {
                setAccountTypeOptions(response.data.map(accountType => {
                    return {
                        id: accountType.id,
                        accountType: accountType.accountType,
                        label: accountType.accountType
                    }
                }));
            }
            const response2 = await getIdiCoreStatusList();
            if (response2.status === 200) {
                dispatch(setIdiCoreStatusList(response2.data.returnObject));
            }
        }
        fetchAccountTypes();
        return () => {
            ignore = true;
        }
    }, [])

    return (
        <div className="owner-search-container">
            <div className="account-contact-container">
                <ObjectPicklist label="Owner Profile"
                                value={accountType}
                                options={accountTypeOptions}
                                onChange={(value) => dispatch(setAccountType(value))}/>
                <Picklist label="Company Address USPS Verified"
                          value={companyAddressUSPSVerified}
                          options={["All", "Y", "N"]}
                          onChange={(value) => dispatch(setCompanyAddressUSPSVerified(value))}/>
                <Picklist label="Primary Contact Has Email"
                          value={primaryContactHasEmail}
                          options={["All", "Y", "N"]}
                          onChange={(value) => dispatch(setPrimaryContactHasEmail(value))}/>
                <Picklist label="Primary Contact Has Phone"
                          value={primaryContactHasPhone}
                          options={["All", "Y", "N"]}
                          onChange={(value) => dispatch(setPrimaryContactHasPhone(value))}/>
                <ObjectPicklist label="IDI Core Status"
                          value={idiCoreStatus}
                          options={[{ id: 0, name: 'All', sortOder: 0 }, ...idiCoreStatusList]}
                          displayKey={'name'}
                          onChange={(value) => dispatch(setIdiCoreStatus(value))}/>
                <SearchCheckbox label="Include Secondary Owners"
                                value={includeNonPrimaryOwners}
                                onChange={(newValue) => dispatch(setIncludeNonPrimaryOwners(newValue))}/>
            </div>
            <div className="portfolio-property-container">
                <NumericRange label="Total Portfolio Value"
                              minValue={portfolioValueMin}
                              maxValue={portfolioValueMax}
                              onMinChange={(value) => dispatch(setPortfolioValueMin(value))}
                              onMaxChange={(value) => dispatch(setPortfolioValueMax(value))}/>
                <NumericRange label="Total Properties Owned"
                              minValue={totalPropertiesOwnedMin}
                              maxValue={totalPropertiesOwnedMax}
                              onMinChange={(value) => dispatch(setTotalPropertiesOwnedMin(value))}
                              onMaxChange={(value) => dispatch(setTotalPropertiesOwnedMax(value))}/>
            </div>
        </div>
    )
}

export default OwnerSearchPanel;