import moment from 'moment';
import { getStates } from '../../main/API/leadMakerSearchApi'
// this email regx validates emails as follows:
// email must be in the format local@domain.suffix
// local must consist of a-z,A-Z,0-9 and special characters !#$%&’*+/=?^_`{|}~-
// local can contain a . (dot) but must not start or end with one
// domain must start with a-z,A-Z, can contain a-z,A-Z and -
// suffix can be 2 or 3 characters and must be a-z,A-Z
export const EMAIL_REGX = new RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@[a-zA-Z][a-zA-Z0-9-]+\.[a-zA-Z]{2,3}$/);
export const ZIPCODE_REGX = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);
export const CANADIAN_POSTAL_REGX = new RegExp(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i)

export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A'
export const DATE_FORMAT = 'MM/DD/YYYY';

export const toGoogleMapLink = (lat, lon) => {
  const googleMapUrl = `http://www.google.com/maps/place/${lat},${lon}/@${lat},${lon},20z/data=!3m1!1e3`;
  return encodeURI(googleMapUrl);
}

export const dateFormatter = (dateFormat) => (params) => {
  if (!params.value) {
    return ''
  }
  return moment(params.value).format(dateFormat);
}

export const twoDecimalFormatter = (params) => {
  return Number.parseFloat(params.value).toFixed(2);
}

export const currencyFormatter = (params) => {
  return  (params.value == null)
    ? null
    : '$' + Math.floor(params.value).toLocaleString();
}

export const probabilityFormatter = (params) => {
   return (params.value == null)
      ? null
      : Math.floor(params.value).toLocaleString() + '%';
}

export const removeEmpty = (obj) =>  {
  const newObj = {};
  Object.keys(obj).forEach(function(k) {
    if (obj[k] && typeof obj[k] === "object") {
      const value = removeEmpty(obj[k]);
      if (Object.keys(value).length !== 0) {
        newObj[k] = removeEmpty(obj[k]);
      }
    } else if (obj[k] != null && obj[k] !== undefined) {
      newObj[k] = obj[k];
    }
  });
  return newObj;
}

export const stateOptionsFetch = async (input, callback) => {
    const response = await getStates(input);
    if (response.status === 200){
        callback(response.data.map(state => {
            return {label: state.state, abbreviation: state.stateAbbreviation}
        }))
    }
}
