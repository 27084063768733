import "./MiscSearchPanel.scss";
import "./BuildingSearchPanel.scss";
import Picklist from "../SearchInputs/Picklist";
import DateRange from "../SearchInputs/DateRange";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateAddedToDbMax,
    selectDateAddedToDbMin,
    selectIsAccountBasedResearch,
    selectIsVerifiedOwner,
    selectIsVerifiedOwnerProfile,
    selectIsVerifiedPrimary,
    selectPreviouslyExported,
    selectPropertyInSalesforce,
    selectRoofResearchCompleted,
    selectSource,
    setDateAddedToDbMax,
    setDateAddedToDbMin, setIsAccountBasedResearch, setIsVerifiedOwner, setIsVerifiedOwnerProfile, setIsVerifiedPrimary,
    setPreviouslyExported,
    setPropertyInSalesforce,
    setRoofResearchCompleted,
    setSource
} from "../../../../main/store/slices/leadMakerSearchSlice";
import {useEffect, useState} from "react";
import {getDataSourceList} from "../../../../main/API/uploadApi";
import ObjectPicklist from "../SearchInputs/ObjectPicklist";
import SearchCheckbox from "../SearchInputs/SearchCheckbox";

const MiscSearchPanel = () => {
    const dispatch = useDispatch();
    const propertyInSalesforce = useSelector(selectPropertyInSalesforce);
    const previouslyExported = useSelector(selectPreviouslyExported);
    const dateAddedToDBMin = useSelector(selectDateAddedToDbMin);
    const dateAddedToDBMax = useSelector(selectDateAddedToDbMax);
    const roofResearchCompleted = useSelector(selectRoofResearchCompleted);
    const isVerifiedOwnerProfile = useSelector(selectIsVerifiedOwnerProfile);
    const isVerifiedPrimary = useSelector(selectIsVerifiedPrimary);
    const isVerifiedOwner = useSelector(selectIsVerifiedOwner);
    const isAccountBasedResearch = useSelector(selectIsAccountBasedResearch);
    const source = useSelector(selectSource);

    // Initalize the options for the Source fields
    // The default values are the only options initially
    const [sourceOptions, setSourceOptions] = useState([source]);
    
    // Fetch the options for sources in the Source field
    useEffect(() => {
        let ignore = false;
        const fetchSources = async () => {
            const response = await getDataSourceList()
            if(response.status === 200 && !ignore){
                const returnedOptions = response.data.returnObject.map(source => {
                    return {
                        id: source.id,
                        label: source.name
                    }});
                // Use the spread operator since the "All Data Sources" default value isn't returned from the API
                setSourceOptions([{id: 0, label: 'All Sources'}, ...returnedOptions]);
            }
        }
        fetchSources();

        return () => {
            ignore = true;
        };
    }, []);

    return (
        <div className="misc-search-container">
            <div className="misc-search-column">
                <Picklist
                    label="Property in Salesforce"
                    value={propertyInSalesforce}
                    options={["All", "Y", "N"]}
                    onChange={(newValue) => dispatch(setPropertyInSalesforce(newValue))}/>
                <Picklist
                    label="Previously Exported"
                    value={previouslyExported}
                    options={["All", "Y", "N"]}
                    onChange={(newValue) => dispatch(setPreviouslyExported(newValue))}/>
            </div>
            <div className="misc-search-column">
                <Picklist
                    label="Roof Research Completed"
                    value={roofResearchCompleted}
                    options={["All", "Y", "N"]}
                    onChange={(newValue) => dispatch(setRoofResearchCompleted(newValue))}/>
                <ObjectPicklist
                    label="Source"
                    value={source}
                    options={sourceOptions}
                    textWidth='0.7'
                    onChange={(newValue) => dispatch(setSource(newValue))}/>
            </div>
            <div className="misc-search-column">
                <SearchCheckbox
                  label="Verified Owner Profile"
                  value={isVerifiedOwnerProfile}
                  onChange={(newValue) => dispatch(setIsVerifiedOwnerProfile(newValue))}/>
                <SearchCheckbox
                  label="Verified Primary"
                  value={isVerifiedPrimary}
                  onChange={(newValue) => dispatch(setIsVerifiedPrimary(newValue))}/>
            </div>
            <div className="misc-search-column">
                <SearchCheckbox
                  label="Verified Owner"
                  value={isVerifiedOwner}
                  onChange={(newValue) => dispatch(setIsVerifiedOwner(newValue))}/>
                <SearchCheckbox
                  label="Account Based Research"
                  value={isAccountBasedResearch}
                  onChange={(newValue) => dispatch(setIsAccountBasedResearch(newValue))}/>
            </div>
            <div className='misc-search-span'>
                <DateRange
                  label="Date Added to DB"
                  minValue={dateAddedToDBMin}
                  maxValue={dateAddedToDBMax}
                  onMinChange={(newValue) => dispatch(setDateAddedToDbMin(newValue))}
                  onMaxChange={(newValue) => dispatch(setDateAddedToDbMax(newValue))}/>
            </div>
        </div>
    )
}

export default MiscSearchPanel;
