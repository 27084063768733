
import './CampaignDefinitionSelectField.scss';
import React from "react";
import { FormHelperText, FormControl, Select, MenuItem, InputLabel } from "@mui/material";

const CampaignDefinitionSelectField = ({ id, label, value, fieldWidth, fieldHeight, options, error, onChange, defaultValue }) => {
  return (
    <div className="flex-row">
      <div className="inline-label campaign-definition-select-label">{label}:</div>
      <FormControl sx={{ width: '350px'}}>
        <Select id={label}
            value={value}
            label={label}
            size="small"
            error={error}
            onChange={(event) => onChange(event.target.value, id)}>
              <MenuItem value={''} disabled hidden>{defaultValue}</MenuItem>
            {options.map((option) => <MenuItem key={option.id} value={option.id}>{option.value}</MenuItem>)}
        </Select>
        {error ? <FormHelperText error={error}>{error}</FormHelperText> : null}
      </FormControl>
    </div>
  );
}

export default CampaignDefinitionSelectField;