import { Checkbox, FormControl, FormGroup, FormHelperText } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";

const SearchCheckbox = ({ label, value, helperText, fieldWidth, fieldHeight, onChange}) => {
  
  const styleProps = () => {
    let styleProps = {};
    if (fieldWidth) {
      styleProps = {...styleProps, width: fieldWidth}
    }
    if (fieldHeight) {
      styleProps = {...styleProps, height: fieldHeight}
    }
    return styleProps;
  }
  
  return (
    <>
      <FormControl>
        <FormGroup
          sx={styleProps}
        >
          <FormControlLabel control={<Checkbox
            checked={value}
            onChange={(e) =>
              onChange(e.target.checked)}
          />} label={label} />
        </FormGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </>
  )
}

export default SearchCheckbox;
