
import './ResearchDetail.scss';
import ResearchDetailTextField from "../../library/components/ResearchDetail/ResearchDetailTextField";
import ResearchDetailSelectField from '../../library/components/ResearchDetail/ResearchDetailSelectField';
import ResearchDetailContact from '../../library/components/ResearchDetail/ResearchDetailContact';
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import React, {useEffect, useState} from 'react';
import {EMAIL_REGX, toGoogleMapLink, ZIPCODE_REGX} from "../../library/common/Utils";
import * as Yup from "yup";
import "yup-phone-lite";
import {getAccountTypes, getIdiCoreStatusList, getIndustries, getStates} from "../../main/API/leadMakerSearchApi";
import {useDispatch, useSelector} from "react-redux";
import {
  clearOwnerResearchDetails,
  fetchAvailableOwnerStats,
  fetchOwnerResearchDetails,
  selectOwnerResearchDetails,
  selectDataFound,
  selectTotalAvailableOwnerProps,
  setOwnerResearchDetails,
  blankOwnerRecord,
  transformInboundContacts
} from "../../main/store/slices/ownerResearchSlice";
import {setToastMessage} from "../../main/store/slices/utilSlice";
import toastTypes from "../../library/constants/toastTypes";
import {checkIdiCore, submitOwnerResearchDetails, submitCompletedOwnerResearchDetails} from "../../main/API/ownerResearchApi";
import {transformResearchDetailPayload} from "../../library/components/ResearchDetail/ResearchDetailPayloadTransformer";
import {useNavigate} from "react-router-dom-v5-compat";
import ResearchDetailCheckboxField from "../../library/components/ResearchDetail/ResearchDetailCheckboxField";

const ResearchDetail = () => {
  
  const OWNER_RESEARCH_ERROR_MSG = 'Owner must be verified to save. If unable to verify owner profile, select "Needs More Research"';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [stateOptions, setStateOptions] = useState([]);
  const [idiStatusList, setIdiStatusList] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [industries, setIndustries] = useState([]);
  const ownerResearchData = useSelector(selectOwnerResearchDetails);
  const [payload, setPayload] = useState({...blankOwnerRecord});
  const propertyCount = useSelector(selectTotalAvailableOwnerProps);
  const dataFound = useSelector(selectDataFound);
  
  useEffect(() => {
    setPayload({...ownerResearchData});
  }, [ownerResearchData]);

  useEffect(() => {
    dispatch(fetchOwnerResearchDetails());
    dispatch(fetchAvailableOwnerStats());
  }, []);
  
  const companySchema = Yup.object().shape({
    name: Yup.string()
      .required("Company Name is required")
      .max(80, "Cannot exceed 80 characters"),
    address1: Yup.string(),
    city: Yup.string()
      .max(100, "Cannot exceed 100 characters"),
    state: Yup.string()
      .length(2, "State must be 2 characters"),
    zip: Yup.string()
      .min(5, "Must have at least 5 digits")
      .max(10, "Must not exceed 10 digits")
      .matches(ZIPCODE_REGX, {
        message:'Please enter a 5-digit (XXXXX) or 9-digit (XXXXX-XXXX) Zip Code',
        excludeEmptyString: true
      }),
    website: Yup.string()
      .optional()
      .nullable()
      .max(255, "Cannot exceed 255 characters"),
    linkedIn: Yup.string()
      .optional()
      .nullable()
      .max(255, "Cannot exceed 255 characters"),
    accountType: Yup.string()
      .required("Owner Profile is required")
  });
  
  const contactSchema = Yup.object().shape({
      firstName: Yup.string()
        .nullable()
        .max(40, "First Name cannot exceed 40 characters"),
      lastName: Yup.string().required("Last Name is required")
        .max(80, "Last Name cannot exceed 80 characters"),
      title: Yup.string()
        .nullable()
        .max(128, "Title cannot exceed 128 characters"),
      company: Yup.string()
        .nullable()
        .max(80, "Company cannot exceed 80 characters"),
      address1: Yup.string()
        .nullable()
        .max(255, "Address cannot exceed 255 characters"),
      address2: Yup.string()
        .nullable()
        .max(255, "Address cannot exceed 255 characters"),
      city: Yup.string()
        .nullable()
        .max(100, "City cannot exceed 100 characters"),
      state: Yup.string()
        .nullable()
        .max(255, "Address cannot exceed 255 characters"),
      zip: Yup.string()
      .nullable()
      .optional()
      .max(10, "Must not exceed 10 digits")
      .matches(ZIPCODE_REGX, {
        message:'Please enter a 5-digit (XXXXX) or 9-digit (XXXXX-XXXX) Zip Code',
        excludeEmptyString: true
      }),
      email_2: Yup.string()
        .nullable()
        .optional()
        .matches(EMAIL_REGX, {
          message: "Email must contain a valid email address",
          excludeEmptyString: true
        }),
      email_3: Yup.string()
        .nullable()
        .optional()
        .matches(EMAIL_REGX, {
          message: "Email must contain a valid email address",
          excludeEmptyString: true
        }),
      email_4: Yup.string()
        .nullable()
        .optional()
        .matches(EMAIL_REGX, {
          message: "Email must contain a valid email address",
          excludeEmptyString: true
        }),
      email_5: Yup.string()
        .nullable()
        .optional()
        .matches(EMAIL_REGX, {
          message: "Email must contain a valid email address",
          excludeEmptyString: true
        }),
      phone_1: Yup.string()
        .when('phone_1', {
          is: (value) => value?.length > 0,
          then: () => Yup.string().phone("US", "Please enter a valid phone number"),
          otherwise: () => Yup.string().nullable(),
        }),
      phone_2: Yup.string()
        .when('phone_2', {
          is: (value) => value?.length > 0,
          then: () => Yup.string().phone("US", "Please enter a valid phone number"),
          otherwise: () => Yup.string().nullable(),
        }),
      phone_3: Yup.string()
        .when('phone_3', {
          is: (value) => value?.length > 0,
          then: () => Yup.string().phone("US", "Please enter a valid phone number"),
          otherwise: () => Yup.string().nullable(),
        }),
      phone_4: Yup.string()
        .when('phone_4', {
          is: (value) => value?.length > 0,
          then: () => Yup.string().phone("US", "Please enter a valid phone number"),
          otherwise: () => Yup.string().nullable(),
        }),
      phone_5: Yup.string()
        .when('phone_5', {
          is: (value) => value?.length > 0,
          then: () => Yup.string().phone("US", "Please enter a valid phone number"),
          otherwise: () => Yup.string().nullable(),
        }),
      phone_1_type: Yup.string()
        .when('phone_1', {
          is: (value) => value?.length > 0,
          then: () => Yup.string().required('Missing type'),
          otherwise: () => Yup.string().nullable(),
        }),
      phone_2_type: Yup.string()
        .when('phone_2', {
          is: (value) => value?.length > 0,
          then: () => Yup.string().required('Missing type'),
          otherwise: () => Yup.string().nullable(),
        }),
      phone_3_type: Yup.string()
        .when('phone_3', {
          is: (value) => value?.length > 0,
          then: () => Yup.string().required('Missing type'),
          otherwise: () => Yup.string().nullable(),
        }),
      phone_4_type: Yup.string()
        .when('phone_4', {
          is: (value) => value?.length > 0,
          then: () => Yup.string().required('Missing type'),
          otherwise: () => Yup.string().nullable(),
        }),
        phone_5_type: Yup.string()
          .when('phone_5', {
            is: (value) => value?.length > 0,
            then: () => Yup.string().required('Missing type'),
            otherwise: () => Yup.string().nullable(),
          }),
      },
    [
      ['phone_1', 'phone_1'],
      ['phone_2', 'phone_2'],
      ['phone_3', 'phone_3'],
      ['phone_4', 'phone_4'],
      ['phone_5', 'phone_5'],
    ]
  );
  
  useEffect(() => {
    async function fetchStates() {
      const {data} = await getStates("");
      // console.log('state data: ', data);
      const results = [];
      data.forEach((value) => {
        results.push({
          id: value.stateAbbreviation,
          value: value.stateAbbreviation,
        });
      });
      setStateOptions(results);
    }
    fetchStates();

    async function fetchIdiCoreStatusList() {
      const { data } = await getIdiCoreStatusList();
      // console.log('state data: ', data);
      const results = [];
      (data?.returnObject || []).forEach(({id, name}) => {
        results.push({
          id,
          value: name,
        });
      });
      setIdiStatusList(results);
    }
    fetchIdiCoreStatusList();
    
    async function fetchAccountTypes() {
      let response;
      try {
        response = await getAccountTypes();
      } catch (error) {
        console.error(`Unable to fetch account types: ${error}`);
        return
      }
      const results = [];
      response?.data.forEach((value) => {
        results.push({
          id: value.id,
          value: value.accountType,
        });
      });
      setAccountTypes(results);
    }
    fetchAccountTypes();
    
    // async function fetchIndustries() {
    //   let response;
    //   try {
    //     response = await getIndustries();
    //   } catch (error) {
    //     console.error(`Unable to fetch industries: ${error}`);
    //     return
    //   }
    //   const results = [];
    //   response?.data.forEach((value) => {
    //     results.push({
    //       id: value.id,
    //       value: value.industry,
    //     });
    //   });
    //   setIndustries(results);
    // }
    // fetchIndustries();
  }, []);
  
  const handleChange = (value, object, fieldName, index) => {
    let tempErrors = {...errors};

    if (index != null) {
      if (tempErrors?.[object]?.length) {
        tempErrors[object] = tempErrors?.[object]?.map((error) => {
          const returnValue = { ...error };
          if (error.contactIndex === index) {
            delete returnValue[fieldName];
          }
          return returnValue;
        });
      }
      const newObject = [...payload[object]];
      newObject.splice(index, 1, { ...newObject[index], [fieldName]: value })
      const newPayload = {...payload, [object]: newObject };
      setPayload(newPayload);
    } else {
      // console.log('top level change for fieldName ', fieldName, ' - new value: ', value);
      const newPayload = {...payload, [fieldName]: value };
      setPayload(newPayload);
      delete tempErrors[fieldName]
    }
    setErrors(tempErrors);
  }

  
  const handlePrimaryButtonClick = (newPrimary) => {
    setPrimaryContact(newPrimary.target.value);
  }

  const setPrimaryContact = (index) => {
    const newContacts = payload.contacts.map((contact, i) => (
      { ...contact, primary : (i == index) }));
    dispatch(setOwnerResearchDetails({...payload, contacts: newContacts }));
  }
  const newContact = {
    firstName : "New Contact",
    lastName : "",
    title: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    company : "",
    email_1 : "",
    email_2 : "",
    email_3 : "",
    email_4 : "",
    email_5: "",
    phone_1: "",
    phone_1_type : "",
    phone_2 : "",
    phone_2_type : "",
    phone_3 : "",
    phone_3_type : "",
    phone_4 : "",
    phone_4_type : "",
    phone_5 : "",
    phone_5_type : "",
    description : "",
    primary: false,
    isNew: true,
  }
  
  const addContact = () => {
    // setPayload((payload) => ({...payload, contacts: [ ...payload.contacts, newContact ] }));
    dispatch(setOwnerResearchDetails({...payload, contacts: [ ...payload.contacts, newContact ] }));
  }
  
  const validateForm = (fullValidate = true) => {
    let newErrors = {};
    try {
      companySchema.validateSync(payload, { abortEarly: false });
    } catch (error) {
      newErrors = error.inner.reduce((acc, cur) => {
        acc[cur.path] = cur.message;
        return acc;
      }, {});
    }
    // check research status
    if (payload.needsMoreResearch && fullValidate) {
      // if needs more is checked, everything but account-based must be false
      if (
        payload.isVerifiedOwnerProfile ||
        payload.isVerifiedPrimary ||
        payload.isVerifiedOwner) {
        newErrors = {...newErrors, "needsMoreResearch": OWNER_RESEARCH_ERROR_MSG};
      }
    } else if (fullValidate) {
      // Needs more is NOT checked - must have Verified Owner profile
      if (!payload.isVerifiedOwnerProfile) {
        newErrors = {...newErrors, "needsMoreResearch": OWNER_RESEARCH_ERROR_MSG};
      } else {
        // if owner profile is verified, Owner OR Primary must be checked
        if (!payload.isVerifiedPrimary &&
          !payload.isVerifiedOwner) {
          newErrors = {...newErrors, "needsMoreResearch": OWNER_RESEARCH_ERROR_MSG};
        }
      }
    }
    let contactErrors = [];
    payload.contacts.map((contact, index) => {
      let thisContactErrors = {};
      try {
        contactSchema.validateSync(contact, { abortEarly: false });
      } catch (error) {
        thisContactErrors = error?.inner?.reduce((acc, cur) => {
          acc[cur.path] = cur.message;
          return acc;
        }, {}) || {};
      }
      if (contact.address1 || contact.city || contact.state || contact.zip) {
          if (!contact.address1) {
            thisContactErrors.address1 = 'Required, no partial addresses'
          }
          if (!contact.city) {
            thisContactErrors.city = 'Required, no partial addresses'
          }
          if (!contact.state) {
            thisContactErrors.state = 'Required, no partial addresses'
          }
          if (!contact.zip) {
            thisContactErrors.zip = 'Required, no partial addresses'
          }
        }
        if (Object.keys(thisContactErrors)?.length) {
          contactErrors.push({...thisContactErrors, "contactIndex" : index});
        }
    })
    return {...newErrors, ...(contactErrors.length > 0 && { "contacts" : contactErrors})};
  }
  
  const savePayload = async (payload, isResearchCompleted = false) => {
    // console.log('submitting: ', payload);
    try {
      if (isResearchCompleted)
        return await submitCompletedOwnerResearchDetails(transformResearchDetailPayload(payload));
      else
        return await submitOwnerResearchDetails(transformResearchDetailPayload(payload));
    } catch(error) {
      console.error(`Error: ${error}`);
      return false;
    }
  }

  const transformContactsForUpdate = () => {
    return {
      ...payload,
      contacts: payload.contacts.map((contact) => {
        const zipCodeArray = contact.zip.split('-');
        const innerReturnValue = {
          id: contact.id,
          address: {
            address1: contact.address1,
            address2: contact.address2,
            city: contact.city,
            state: contact.state,
            zip5: zipCodeArray[0] || null,
            zip4: zipCodeArray[1] || null
          },
          company: contact.company,
          firstName: contact.firstName,
          lastName: contact.lastName,
          primary: contact.primary,
          title: contact.title,
          emails: [],
          phones: []
        }
        for (let i = 1; i <= 5; i++) {
          const emailKeyString = `email_${i}`;
          if (contact[emailKeyString]) innerReturnValue.emails.push({
            email: contact[emailKeyString]
          });
          const phoneKeyString = `phone_${i}`;
          const phoneTypeKeyString = `phone_${i}_type`;
          if (contact[phoneKeyString] && contact[phoneTypeKeyString]) innerReturnValue.phones.push({
            phoneNumber: contact[phoneKeyString],
            phoneType: contact[phoneTypeKeyString]
          });
        }
        return innerReturnValue;
      })
    }
  }
  
  const updateForm = async (isResearchCompleted = false, fullValidate = true) => {
    let formErrors = validateForm(fullValidate);
    // console.log("in save- errors are:", JSON.stringify(formErrors));
    setErrors(formErrors);
    if (!formErrors || (Object.keys(formErrors).length === 0)) {
      const newPayload = transformContactsForUpdate();
      const response = await savePayload(newPayload, isResearchCompleted);
      if (response.data) {
        dispatch(setToastMessage({
          message: "Research Detail Saved",
          type: toastTypes.success
        }))
        return response.data;
      } else {
        dispatch(setToastMessage({
          message: "Problem saving Research Detail",
          type: toastTypes.error
        }))
        return false;
      }
    } else {
      dispatch(setToastMessage({
        message: "Unable to save Research Detail -- see errors in form.",
        type: toastTypes.error
      }))
      return false;
    }
  }

  const isContactBlank = (contact) => {
    for (var prop in contact) {
      if (prop !== "primary") {
        if ((contact[prop] !== newContact[prop]) && (contact[prop] !== "")) {
          return false;
        }
      }
    }
    return true;
  }
  
  const removeContact = (index) => {
    const contactToRemove = payload.contacts[index];
    // make sure they haven't entered any data
    if (!isContactBlank(contactToRemove)) {
      dispatch(setToastMessage({
        message: `This contact has data - clear all data before removing.`,
        type: toastTypes.error
      }))
      return;
    }
    // choose another primary if the one to be removed is primary and there are other contacts
    if (contactToRemove.primary && payload.contacts.length > 1) {
      setPrimaryContact(index === 0 ? 1 : 0);
    }
    let newContactArray = [...payload.contacts];
    newContactArray.splice(index, 1);
    // setPayload({...payload, contacts : newContactArray});
    dispatch(setOwnerResearchDetails({...payload, contacts : newContactArray}));
    dispatch(setToastMessage({
      message: `Contact ${index + 1} removed.`,
      type: toastTypes.success
    }));
  }
  
  const saveAndExit = () => {
    updateForm(true)
      .then(ret => {
        if (ret) {
          dispatch(clearOwnerResearchDetails());
          exitPage();
        }
      });
  }
  
  const saveAndNext = () => {
    updateForm(true)
      .then(ret => {
        if (ret) {
          dispatch(clearOwnerResearchDetails());
          dispatch(fetchOwnerResearchDetails());
          dispatch(fetchAvailableOwnerStats());
        }
      });
  }

  const handleSubmitToIdiCore = () => {
    updateForm(false, false)
      .then(({ returnObject }) => {
        if (returnObject){
          const newPayload = { companyId: payload.companyId };
          checkIdiCore(newPayload).then((response) => {
            const verifiedContacts = response.data.returnObject || [];
            const currentContacts = payload.contacts;
            let newContacts = [];
            currentContacts.forEach((curr, index) => {
              const verifiedContact = verifiedContacts.find((ver) => ver.id === curr.id);
              if (verifiedContact) {
                delete verifiedContact.primary;
                newContacts.push({...newContact, ...verifiedContact})
              }
            })
            newContacts = transformInboundContacts(newContacts);
            const newPayload = {...returnObject, contacts: newContacts}
            dispatch(setOwnerResearchDetails(newPayload));
          }).catch((e) => {
            dispatch(setToastMessage({
              type: toastTypes.error,
              message: 'There was an issue processing the IDI Core check, please try again.'
            }))
          })
        }
      })
  }

  const exitPage = () => {
    navigate("/");
  }
  
  return <div className="research-detail-wrapper">
              <div className="flex-header flex-row space-between">
                  <div className="component-title research-detail-title-tight">
                      Owner Research
                  </div>
                  <div className="form-control row  research-detail-button">
                      <Button variant='outlined' style={{ marginRight: "0.5em" }} onClick={handleSubmitToIdiCore} className="button">Check IDI Core</Button>
                      <Button variant='outlined' style={{ marginRight: "0.5em" }} onClick={saveAndNext} className="button">Save and Next</Button>
                      <Button variant='outlined' onClick={saveAndExit} className="button">Save and Exit</Button>
                    </div>
              </div>

              <div className="form">
                <div className="form-row research-detail-prop-row">
                  <div className="inline-label">Total Properties Available to Update:</div>
                  <div>{propertyCount?.toLocaleString()}</div>
                </div>
              </div>
    
            {!dataFound &&
              <div className="form-row  research-detail-message">
                *** No Owner Research Available ***
              </div>
            }
              <div className="form">
                <div className="form-row">
                  <div className="form-control row research-detail-form-control-row">
                    <ResearchDetailTextField object="company" fieldName="name" label="Company Name"
                                             value={payload.name} fieldWidth="50em" fixedLabel="true"
                                             onChange={handleChange} error={errors?.name}
                    />
                    <div className="research-detail-spacer-field">&nbsp;&nbsp;</div>
                  </div>
                  <div className="form-control row research-detail-form-control-row"  >
                    <ResearchDetailTextField  object="company" fieldName="address1" label="Address"
                                              value={payload.address1} fieldWidth="13.75em" fixedLabel="true"
                                              onChange={handleChange} error={errors?.address1} />
                    <ResearchDetailTextField  object="company" fieldName="city" label="City"
                                              value={payload.city} fieldWidth="12.25em"
                                              onChange={handleChange} error={errors?.city}  />
                    <ResearchDetailSelectField
                      id="state"
                      label="State"
                      object="company"
                      fieldName="state"
                      value={payload.state}
                      options={stateOptions}
                      error={errors?.state}
                      onChange={handleChange} />
                    <ResearchDetailTextField  object="company" fieldName="zip" label="Zip Code"
                                              value={payload.zip} fieldWidth="6.25em"
                                              onChange={handleChange} error={errors?.zip} />
                  </div>
                  <div className="form-control row research-detail-form-control-row"  >
                    <ResearchDetailTextField object="company" fieldName="website"  label="Website"
                                             value={payload.website} fieldWidth="15.5em" fixedLabel="true"
                                             onChange={handleChange} error={errors?.website} />
                    <div style={{ width: "5.2em" }}>&nbsp;</div>
                    <ResearchDetailTextField object="company" fieldName="linkedIn" label="Company LinkedIn"
                                             value={payload.linkedIn} fieldWidth="20.5em"
                                             onChange={handleChange} error={errors?.linkedIn} />
                  </div>
                  <div className="form-control row research-detail-form-control-row"  >
                    <ResearchDetailSelectField
                      id="accountType"
                      label="Owner Profile"
                      object="company"
                      fieldName="accountType"
                      fieldWidth="24em"
                      value={payload.accountType}
                      options={accountTypes}
                      error={errors?.accountType}
                      onChange={handleChange}
                      fixedLabel="true"
                       />
                    {/* <ResearchDetailSelectField
                      id="industry"
                      label="Industry"
                      object="company"
                      fieldName="industry"
                      fieldWidth="25.4em"
                      value={payload.industry}
                      options={industries}
                      error={errors?.industry}
                      onChange={handleChange}
                      fixedLabel="true"
                    /> */}
                  </div>
                  <div className="form-control row research-detail-form-control-row"  >
                      <ResearchDetailCheckboxField
                        id="isVerifiedOwnerProfile"
                        label="Verified Owner Profile"
                        object="company"
                        fieldName="isVerifiedOwnerProfile"
                        fieldWidth="20em"
                        indent={true}
                        value={payload.isVerifiedOwnerProfile}
                        error={errors?.isVerifiedOwnerProfile}
                        onChange={handleChange}
                      />
                      <ResearchDetailCheckboxField
                        id="isVerifiedPrimary"
                        label="Verified Primary"
                        object="company"
                        fieldName="isVerifiedPrimary"
                        fieldWidth="20em"
                        value={payload.isVerifiedPrimary}
                        error={errors?.isVerifiedPrimary}
                        onChange={handleChange}
                      />
                    <ResearchDetailCheckboxField
                      id="isVerifiedOwner"
                      label="Verified Owner"
                      object="company"
                      fieldName="isVerifiedOwner"
                      fieldWidth="20em"
                      value={payload.isVerifiedOwner}
                      error={errors?.isVerifiedOwner}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-control row research-detail-form-control-row"  >
                      <ResearchDetailCheckboxField
                        id="isAccountBasedResearch"
                        label="Account Based Research"
                        object="company"
                        fieldName="isAccountBasedResearch"
                        fieldWidth="20em"
                        indent={true}
                        value={payload.isAccountBasedResearch}
                        error={errors?.isAccountBasedResearch}
                        onChange={handleChange}
                      />
                      <ResearchDetailCheckboxField
                        id="needsMoreResearch"
                        label="Needs More Research"
                        object="company"
                        fieldName="needsMoreResearch"
                        fieldWidth="20em"
                        value={payload.needsMoreResearch}
                        error={errors?.needsMoreResearch}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="inline-label research-detail-label">Asset(s)</div>
                </div>
                <hr/>
                <div className="form-row">&nbsp;</div>
                { payload?.companyAssets && payload?.companyAssets.map((asset, key) => {
                  return (
                    <div key={key} className="research-detail-asset">
                      <div >{asset.fullAddress}</div>
                      <a href={toGoogleMapLink(asset.latitude, asset.longitude)}
                         target="_blank">Google Maps Link</a>
                    </div>
                  )})}
                <div className="form-row">&nbsp;</div>
                <div className="form-row">
                  <Button
                    variant='outlined'
                    className="button"
                    onClick={addContact}
                  >Add New Contact</Button>
                </div>
                <div className="form-row">&nbsp;</div>
                  <RadioGroup name="primary-radio-group" onChange={handlePrimaryButtonClick} >
                  { payload?.contacts && payload?.contacts.map((contact, index) => {
                    const contactErrors = errors?.contacts?.find(({contactIndex}) => contactIndex === index);
                    return (
                      <ResearchDetailContact
                        key={index}
                        contact={contact}
                        index={index}
                        handleChange={handleChange}
                        errors={contactErrors}
                        removeContact={removeContact}
                        stateOptions={stateOptions}
                        idiStatusList={idiStatusList}
                      />
                    )
                  })}
                  </RadioGroup>
                
              </div>
              
          </div>
}

export default ResearchDetail;
