
const makePhone = (phone, type) => {
  return {
    phoneNumber : phone,
    phoneType : type
  }
}
export const transformResearchDetailPayload = (data) => {
  console.log('returned data: ', data);
  let newContacts = [];
  data.contacts.forEach((contact) => {
    let emails = [];
    if (contact.email_1) {
      emails.push(contact.email_1)
    }
    if (contact.email_2) {
      emails.push(contact.email_2)
    }
    if (contact.email_3) {
      emails.push(contact.email_3)
    }
    if (contact.email_4) {
      emails.push(contact.email_4)
    }
    
    let phones = [];
    if (contact.phone_1) {
      phones.push(makePhone(contact.phone_1, contact.phone_1_type));
    }
    if (contact.phone_2) {
      phones.push(makePhone(contact.phone_2, contact.phone_2_type));
    }
    if (contact.phone_3) {
      phones.push(makePhone(contact.phone_3, contact.phone_3_type));
    }
    if (contact.phone_4) {
      phones.push(makePhone(contact.phone_4, contact.phone_4_type));
    }
    newContacts.push({...contact, emails: emails, phones: phones })
  });
  return { ...data, contacts : newContacts };
}
