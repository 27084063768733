export const transformAssetResearchPayload = (data) => {
  
  let newData = [];
  data.forEach((property) => {
    if (property.hasNoBuildings) {
      newData.push({...property, roofType: null, roofSqFt: null, buildingSqFt: null});
    } else {
      newData.push(property);
    }
  });
  return newData;
}
