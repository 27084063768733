import { TextField } from '@mui/material';
import './ResearchDetailTextField.scss';

const ResearchDetailTextField = ({ object, fieldName, label, value, fieldWidth, fixedLabel, smallLabel, onChange, error, disabled = false }) => {

  const inputProps = {
    style : {
      padding: 5,
      width: fieldWidth,
    }
  };

  function fieldLabel() {
    var className = "inline-label";
    if (fixedLabel) {
      className += " research-detail-text-label";
    }
    if (smallLabel) {
      className += " small-label"
    }
    // console.log("returned data: ", <div className={className}>{label}:</div>)
      return <div className={className}>{label}:</div>
  }
      
  // console.log('props - ', inputProps);

  return (
    <>
      {fieldLabel()}
      <div className="field-wrapper">
        <TextField 
          fullWidth
          inputProps={inputProps}
          variant="outlined"
          value={value || ""}
          size="small"
          error={!!error}
          label={error}
          disabled={disabled}
          onChange={(e) => {onChange(e.target.value, object, fieldName)}}
          />
      </div>
    </>
  );
}

export default ResearchDetailTextField;