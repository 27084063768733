import hailSolveApi from "../axios/hailSolveApi";

export const getOwnerResearchDetails = async () => {
  const response = await hailSolveApi.get(`Owners/Research`);
  return response;
}

export const submitOwnerResearchDetails = async (payload) => {
  const response = await hailSolveApi.post(`Owners/Research/Save`, payload);
  console.log('api response: ', response);
  return response;
}

export const submitCompletedOwnerResearchDetails = async (payload) => {
  const response = await hailSolveApi.post(`Owners/Research/SaveAndComplete`, payload);
  console.log('api response: ', response);
  return response;
}

export const checkIdiCore = async (payload) => {
  const response = await hailSolveApi.post(`Owners/IdentificationCheck`, payload);
  return response;
}