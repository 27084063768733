import {toGoogleMapLink} from "../../common/Utils";
import ResearchTasksTextField from "./ResearchTasksTextField";
import ResearchTasksSelectField from "./ResearchTasksSelectField";
import {Checkbox} from "@mui/material";
import React from "react";

const ResearchTaskRow = ({ taskData, index, handleChange, taskErrors, roofTypes }) => {
  const handleTaskChange = (value, fieldName) => {
    handleChange(value, fieldName, index);
  }
  
  const buildingChange = (switched) => {
    handleTaskChange(switched, "hasNoBuildings");
  }
  
  return (
    <>
      <div key={taskData.id} className="form-control row research-tasks-grid-item">
        {taskData.fullAddress}
      </div>
      <div className="form-row research-tasks-grid-item">
        {taskData.buildingSqFt}
      </div>
      <div className="form-row research-tasks-grid-item"  style={{ justifyContent: "center" }}>
        {taskData.buildingCount}
      </div>
      <div className="form-row research-tasks-grid-item">
        <ResearchTasksTextField value={taskData.roofSqFt}
                                fieldName="roofSqFt"
                                error={taskErrors?.roofSqFt}
                                onChange={handleTaskChange}
        />
      </div>
      <div className="form-row research-tasks-grid-item">
        <ResearchTasksSelectField
          id="roofType"
          defaultValue="Please Select"
          fieldWidth="12.5em"
          fieldHeight="2.7em"
          value={taskData.roofType}
          options={roofTypes}
          error={taskErrors?.roofType}
          onChange={handleTaskChange}
        />
      </div>
      <div className="form-row research-tasks-grid-item research-tasks-map-link">
        <a href={toGoogleMapLink(taskData.latitude, taskData.longitude)}
           target="_blank">Map Link</a>
      </div>
      <div className="form-row research-tasks-grid-item">
        <ResearchTasksTextField
          fieldName="notes"
          multiline={true}
          value={taskData.notes}
          onChange={handleTaskChange}
          error={taskErrors?.notes} />
      </div>
      <div className="form-row research-tasks-grid-item">
        <Checkbox
          checked={taskData.hasNoBuildings}
          onChange={(e) => buildingChange(e.target.checked)}
            />
      </div>
    </>
  )
}

export default ResearchTaskRow;
