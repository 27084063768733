
import './ResearchDetailCheckboxField.scss';
import { FormGroup, Checkbox, FormHelperText, FormControl } from "@mui/material";
import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";

const ResearchDetailCheckboxField = ({ id, object, fieldName, label, value, indent, fieldWidth, fieldHeight,onChange, error }) => {
  
  function getIndent() {
    if (indent) {
      return 'research-detail-checkbox-offset'
    }
  }
  
  const styleProps = () => {
    let styleProps = {};
    if (fieldWidth) {
      styleProps = {...styleProps, width: fieldWidth}
    }
    if (fieldHeight) {
      styleProps = {...styleProps, height: fieldHeight}
    }
    return styleProps;
  }
  
  return (
    <>
      <FormControl
        error={!!error}
      >
        <FormGroup
          className={getIndent()}
          sx={styleProps}
        >
          <FormControlLabel control={<Checkbox
            id={id}
            checked={value}
            onChange={(e) =>
              onChange(e.target.checked, object, fieldName)}
          />} label={label} />
        </FormGroup>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </>
    )

}

export default ResearchDetailCheckboxField;
