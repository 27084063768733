import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DATE_FORMAT } from 'library/common/Utils';
import moment from 'moment';
import {getOwnerResearchDetails} from "../../API/ownerResearchApi";
import {getOwnerPmtDetails} from "../../API/paymentAPI";

const NO_DATA_FOUND_MESSAGE = "No owner approved for research found";

export const transformInboundContacts = (contacts) => {
  const returnArray = [];
  contacts.forEach((contact) => {
    contact.email_1 = contact.emails[0]?.email || null;
    contact.email_2 = contact.emails[1]?.email || null;
    contact.email_3 = contact.emails[2]?.email || null;
    contact.email_4 = contact.emails[3]?.email || null;
    contact.email_5 = contact.emails[4]?.email || null;
    contact.phone_1 = contact.phones[0]?.phoneNumber || null;
    contact.phone_2 = contact.phones[1]?.phoneNumber || null;
    contact.phone_3 = contact.phones[2]?.phoneNumber || null;
    contact.phone_4 = contact.phones[3]?.phoneNumber || null;
    contact.phone_5 = contact.phones[4]?.phoneNumber || null;
    contact.phone_1_type = contact.phones[0]?.phoneType || null;
    contact.phone_2_type = contact.phones[1]?.phoneType || null;
    contact.phone_3_type = contact.phones[2]?.phoneType || null;
    contact.phone_4_type = contact.phones[3]?.phoneType || null;
    contact.phone_5_type = contact.phones[4]?.phoneType || null;
    contact.phone_1_last_seen = contact.phones[0]?.lastSeen ? moment(contact.phones[0]?.lastSeen).format(DATE_FORMAT) : null;
    contact.phone_2_last_seen = contact.phones[1]?.lastSeen ? moment(contact.phones[1]?.lastSeen).format(DATE_FORMAT) : null;
    contact.phone_3_last_seen = contact.phones[2]?.lastSeen ? moment(contact.phones[2]?.lastSeen).format(DATE_FORMAT) : null;
    contact.phone_4_last_seen = contact.phones[3]?.lastSeen ? moment(contact.phones[3]?.lastSeen).format(DATE_FORMAT) : null;
    contact.phone_5_last_seen = contact.phones[4]?.lastSeen ? moment(contact.phones[4]?.lastSeen).format(DATE_FORMAT) : null;
    contact.address1 = contact?.address?.address1 || "";
    contact.address2 = contact?.address?.address2 || "";
    contact.city = contact?.address?.city || "";
    contact.state = contact?.address?.state || "";
    contact.age = contact.age || "";
    contact.idiLastCheckedDate = contact.idiLastCheckedDate ? moment(contact.idiLastCheckedDate).format(DATE_FORMAT) : "";
    if (contact?.address?.zip5) {
      contact.zip = contact.address?.zip5
      if (contact?.address?.zip4) {
        contact.zip += `-${contact?.address?.zip4}`
      }
    } else {
      contact.zip = "";
    }
    returnArray.push(contact);
  });
  return returnArray;
}
export const fetchOwnerResearchDetails = createAsyncThunk(
  'Owners/Research',
  async (payload, { dispatch }) => {
    const response = await getOwnerResearchDetails();
    if (NO_DATA_FOUND_MESSAGE === response.data.successMessage) {
      dispatch(setDataFound(false));
    }
    else {
      const newContacts = transformInboundContacts(response.data?.returnObject?.contacts || [])
      dispatch(setOwnerResearchDetails({...response.data.returnObject, contacts: [...newContacts]}));
    }
  }
);

export const fetchAvailableOwnerStats = createAsyncThunk(
  'Owners/Payments/Details',
  async (payload, { dispatch }) => {
    // todo -- determine if this is the correct api?
    const response = await getOwnerPmtDetails();
    dispatch(setOwnerResearchStats(response.data.returnObject));
  }
);

export const blankOwnerRecord = {
  "companyId": 0,
    "name": "",
    "address1": "",
    "address2": null,
    "city": "",
    "state": "",
    "zip": "",
    "website": null,
    "linkedIn": null,
    "industry": null,
    "isVerifiedOwnerProfile": null,
    "isVerifiedPrimary": null,
    "isVerifiedOwner": null,
    "isAccountBasedResearch": null,
    "needsMoreResearch": null,
    "accountType": null,
    "companyAssets": [
    {
      "propertyId": 0,
      "fullAddress": "",
      "buildingSqFt": null,
      "buildingCount": null,
      "roofSqFt": null,
      "roofType": null,
      "notes": null,
      "hasNoBuildings": null
    }
  ],
    "contacts": [
    {
      "id": 0,
      "firstName": "",
      "lastName": "",
      "title": null,
      "company": null,
      "emails": [],
      "phones": [
        {
          "phoneNumber": "",
          "phoneType": null
        }
      ],
      "description" : null,
      "primary": false
    }
  ]
};

export const ownerResearchSlice = createSlice({
  name: 'ownerResearchDetails',
  initialState: {
    ownerData: blankOwnerRecord,
    dataFound: false,
    stats : {
        totalProperties: 0,
        updated: 0,
        remaining: 0,
        unpaid: 0,
        totalAvailableToUpdate: 4317,
    }
  },
  reducers: {
    setOwnerResearchDetails: (state, action) => {
        state.dataFound = true;
        state.ownerData = action.payload
    },
    setDataFound: (state, action) => { state.dataFound = action.payload },
    setOwnerResearchStats: (state, action) => { state.stats = action.payload },
    clearOwnerResearchDetails: (state, action) => { state.ownerData = blankOwnerRecord},
  }
});

export const { setOwnerResearchDetails,
                setOwnerResearchStats,
                clearOwnerResearchDetails ,
                setDataFound} = ownerResearchSlice.actions;

export const selectOwnerResearchDetails = state => state.ownerResearchDetails.ownerData;
export const selectTotalAvailableOwnerProps = state => state.ownerResearchDetails.stats.remaining;
export const selectDataFound = state => state.ownerResearchDetails.dataFound;

export default ownerResearchSlice.reducer;
