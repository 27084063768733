
import ResearchDetailTextField from './ResearchDetailTextField';
import ResearchDetailSelectField from './ResearchDetailSelectField';
import '../../../pages/ResearchDetail/ResearchDetail.scss';
import './ResearchDetailContact.scss'
import { phoneTypes } from '../../constants/mockPicklists';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button, Radio } from '@mui/material';
import React from "react";

const ResearchDetailContact = ({ contact, index, handleChange, errors, removeContact, stateOptions, idiStatusList }) => {
  
  const handleContactChange = (value, object, fieldName) => {
    // console.log('firing change w/i contact component with index: ' + index);
    handleChange(value, "contacts", fieldName, index);
  }

  const arrayOf5 = Array.apply(null, Array(5)).map((x, i) => i + 1)

  return (
    <>
    <div className="research-detail-asset">
      <div className="inline-label research-detail-label ">Contact {index + 1}</div>
      <div>
        <FormControlLabel
          value={index}
          control={<Radio />}
          checked={contact.primary}
          label="Primary"
          labelPlacement="end"
        />
      </div>
    </div>
    <hr/>
    <div className="form-row">&nbsp;</div>
    { contact?.isNew &&
    <div className="form-row">
      <Button
        variant='outlined'
        className="research-detail-contact-button"
        onClick={() => removeContact(index)}
      >Remove this Contact</Button>
    </div>
    }
    <div className="form-row">
      <div className="idi-status-container">
        <ResearchDetailSelectField id="idiCoreStatusId" label="IDI Status" object="contact" fieldName="idiCoreStatusId" fieldWidth="25.5em" fixedLabel="true" disabled={true}
                          value={contact.idiCoreStatusId} options={idiStatusList} error={errors?.idiCoreStatusId} idDriven={true} onChange={handleContactChange} />
        <ResearchDetailTextField object="contact" fieldName="idiLastCheckedDate"
                                 label="IDI Check Date" value={contact.idiLastCheckedDate || "No Check Date"} fieldWidth="22.3em" fixedLabel="true" disabled={true}
                                 onChange={handleContactChange} error={errors?.idiLastCheckedDate}  />   
      </div>
      <div className="form-control row research-detail-form-control-row">
        <ResearchDetailTextField object="contact" fieldName="firstName"
                                 label="First Name" value={contact.firstName} fieldWidth="14.5em" fixedLabel="true"
                                 onChange={handleContactChange} error={errors?.firstName}  />
        <ResearchDetailTextField object="contact" fieldName="lastName"
                                 label="Last Name" value={contact.lastName} fieldWidth="18.3em"
                                  onChange={handleContactChange} error={errors?.lastName} />
        <ResearchDetailTextField object="contact" fieldName="age"
                                 label="Age" value={contact.age} fieldWidth="8.9em"
                                  onChange={handleContactChange}/>
      </div>
      <div className="form-control row research-detail-form-control-row"  >
        <ResearchDetailTextField object="contact" fieldName="title"
                                 label="Title" value={contact.title} fieldWidth="21.8em" fixedLabel="true"
                                 onChange={handleContactChange} error={errors?.title}  />
        <ResearchDetailTextField object="contact" fieldName="company"
                                 label=" Company" value={contact.company} fieldWidth="22.1em" fixedLabel="true"
                                 onChange={handleContactChange} error={errors?.company}  />
      </div>
      <div className="form-control row research-detail-form-control-row"  >
        <ResearchDetailTextField object="contact" fieldName="address1"
                                 label="Street" value={contact.address1} fieldWidth="21.8em" fixedLabel="true"
                                 onChange={handleContactChange} error={errors?.address1}  />
        <ResearchDetailTextField object="contact" fieldName="address2"
                                 label="Suite" value={contact.address2} fieldWidth="22.1em" fixedLabel="true"
                                 onChange={handleContactChange} error={errors?.address2}  />
      </div>
      <div className="form-control row research-detail-form-control-row"  >
        <ResearchDetailTextField object="contact" fieldName="city"
                                 label="City" value={contact.city} fieldWidth="21.8em" fixedLabel="true"
                                 onChange={handleContactChange} error={errors?.city}  />
        <ResearchDetailSelectField id="state" label="State" object="contact" fieldName="state" fieldWidth="12.8em" fixedLabel="true"
                      value={contact.state} options={stateOptions} error={errors?.state} onChange={handleContactChange}
        />
        <ResearchDetailTextField object="contact" fieldName="zip"
                                 label="Zip" value={contact.zip} fieldWidth="6.3em" fixedLabel="true" smallLabel="true"
                                 onChange={handleContactChange} error={errors?.zip}  />
      </div>
      {arrayOf5.map((value, index) => {
        const emailString = `email_${value}`;
        const phoneString = `phone_${value}`;
        const phoneTypeString = `phone_${value}_type`;
        const phoneLastSeenString = `phone_${value}_last_seen`;
        return <div className="form-control row research-detail-form-control-row" key={index} >
          <ResearchDetailTextField object="contact" fieldName={emailString}
                                  label={`Email ${value}`} value={contact[emailString]} fieldWidth="21.8em" fixedLabel="true"
                                  onChange={handleContactChange}  error={errors?.[emailString]} />
          <ResearchDetailTextField object="contact" fieldName={phoneString}
                                  label={`Phone ${value}`} value={contact[phoneString]} fieldWidth="10.6em" fixedLabel="true"
                                  onChange={handleContactChange} error={errors?.[phoneString]} />
          <div className="form-control row research-detail-form-control-row"  >
            <ResearchDetailSelectField
              id="phone1Type"
              label={`Type ${value}`}
              showDropDownArrow={false}
              fieldName={phoneTypeString}
              fieldWidth="8em"
              fieldHeight="2.3em"
              value={contact[phoneTypeString]}
              options={phoneTypes}
              error={errors?.[phoneTypeString]}
              onChange={handleContactChange}
              fixedLabel="true"
              smallLabel="true"
              />
              <ResearchDetailTextField object="contact" fieldName={phoneString}
                                  label={`(Last Seen)`} value={contact[phoneLastSeenString]} fieldWidth="7em" fixedLabel="true"
                                  onChange={handleContactChange} error={errors?.[phoneLastSeenString]} disabled={true} />
          </div>
        </div>
      })}
      <div className="form-control row research-detail-form-control-row"  >
        <ResearchDetailTextField object="contact" fieldName="description"
                                 label="Description" value={contact.description} fieldWidth="51.8em" fixedLabel="true"
                                 onChange={handleContactChange} error={errors?.description}  />
      </div>
    </div>
    <div className="form-row">&nbsp;</div>
    </>
  )
}

export default ResearchDetailContact;
