import { TextField, FormControl } from '@mui/material';
import './CampaignDefinitionTextField.scss';

const CampaignDefinitionTextField = ({ fieldName, label, value, onChange, error }) => {

  return (
    <div className="flex-row">
      <div className="inline-label" style={{marginRight: '31px'}}>{label}:</div>
      <FormControl sx={{ width: '350px'}}>
        <TextField
          fullWidth
          variant="outlined"
          value={value}
          size="small"
          error={error}
          helperText={error}
          onChange={(e) => {onChange(e.target.value, fieldName)}}
          />
      </FormControl>
    </div>
  );
}

export default CampaignDefinitionTextField;