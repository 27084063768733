import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { TextField, FormControl, FormHelperText } from '@mui/material';
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";

export const IDIConfirmationDialog = ( props ) => {
  const { open, setOpen, title, selectionCount, setPriority, onConfirm } = props;

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    return () => {
      if (open) {
        setInputValue('');
      }
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="priority-dialog"
    >
      <DialogTitle id="priority-dialog">{title}</DialogTitle>
      <DialogContent>
        <div className="flex-row margin-bottom">
          Confirm submission of {selectionCount} records to IDI Core by entering “Yes”:
        </div>
        <div className="form-row">
          <FormControl sx={{width: '100%'}}>
            <TextField
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={"Yes/No"}
            />
            <FormHelperText>Type 'Yes' to proceed</FormHelperText>
          </FormControl>
        </div>
      </DialogContent>
      
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpen(false)}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          disabled={inputValue?.toLowerCase() !== 'yes'}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}