
import './ResearchDetailSelectField.scss';
import {TextField, Autocomplete} from "@mui/material";
import React from "react";
import { useMemo, useState } from 'react';

const ResearchDetailSelectField = ({ id, object, fieldName, label, value, fieldWidth, fieldHeight, idDriven = false,
                                     showDropDownArrow, options, onChange, fixedLabel, smallLabel, disabled = false, error }) => {

  const [filterValue, setFilterValue] = useState('');

  const onTextChange = (e) => {
    setFilterValue(e.target.value);
    onChange(e.target.value, object, fieldName)
  }

  function fieldLabel() {
    var className = "inline-label";
    if (fixedLabel) {
      className = "inline-label research-detail-select-label";
    }
    if (smallLabel){
      className += ' small-label'
    }
    return <div className={className}>{label}:</div>
  }
  
  const styleProps = () => {
    let styleProps = {};
    if (fieldWidth) {
      styleProps = {...styleProps, width: fieldWidth}
    }
    if (fieldHeight) {
      styleProps = {...styleProps, height: fieldHeight}
    }
    return styleProps;
  }

  const initialValue = useMemo(() => {
    if (idDriven) {
      const initialValueOption = options.find((option) => option.id === value);
      return initialValueOption?.value || '';
    } else {
      return value;
    }
  }, [value, options]);

  const onBlur = () => {
    setFilterValue('');
  }

  return (
    <>
      {fieldLabel()}
      <div >
        <Autocomplete
          id={id}
          freeSolo={false}
          forcePopupIcon={showDropDownArrow}
          inputValue={initialValue || ""}
          includeInputInList={false}
          error={error}
          disabled={disabled}
          onChange={(e, newValue) => onChange(newValue?.value, object, fieldName)}
          options={options}
          getOptionLabel={(option) => option.value}
          isOptionEqualToValue={(option, value) =>  {
            if (idDriven) return option.id === value;
            return option === value;
          }}
          filterOptions={(options) => {
            return options.filter((option) => (option.value)?.toLowerCase().includes(filterValue.toLowerCase()))
          }}
          renderInput={(params) => <TextField {...params} error={error?.length > 0} onChange={onTextChange} onBlur={onBlur} label={error} size="small" />}
          sx={styleProps}
        />
      </div>
      <div>&nbsp;&nbsp;</div>
    </>
  );
}

export default ResearchDetailSelectField;