import {submitLeadSearch} from "../../../../main/API/leadMakerSearchApi";
import {geographicSearchType} from "../../../constants/GeographicSearchConstants";
import {removeEmpty} from "../../../common/Utils";

// Helper function to convert the datetimes coming from
// The datepickers into YYYY-MM-DD format
const convertDateTimeToDate = (dateTime) => {
    const date = new Date(dateTime);
    return date.toISOString().split("T")[0];
}

// Helper function to convert the All/Y/N picklist boolean inputs into
// The correct form for the backend
const convertAllYesNoToBoolean = (value) => {
    if(value === "Y"){
        return true;
    } else if(value === "N"){
        return  false;
    } else {
        return null;
    }
}

export const leadMakerSearchResultsDatasourceFn = (searchParams, totalRowRcvr) => {
    return {
        rowCount: undefined,
        getRows: (params) => {
            if (searchParams.clear) {
                // request to clear the grid, no api call
                params.successCallback([], 0);
                return;
            }
            // Determine the value for state based on the state and city params
            // If the city is populated, use the state from the city
            // Else use abbreviation from the usState field
            // If both are null, use null
            let state = null;
            if(searchParams.city !== null){
                state = searchParams.city.state;
            } else if(searchParams.usState !== null){
                state = searchParams.usState.abbreviation;
            }
            // Create the payload from the searchParams
            let filter = searchParams != null ?
                {
                city: searchParams.city !== null ? searchParams.city.city : null,
                state: state,
                zipCode: searchParams.zipCode,
                radius: searchParams.geographicSearchType == geographicSearchType.RADIUS.id ? searchParams.geographicSearchRadius : null,
                addressType: ((state != null) || (searchParams.zipCode != null)) ? parseInt(searchParams.geographicResultType) : null,
                accountType: searchParams.accountType,
                companyAddressUSPSVerified: convertAllYesNoToBoolean(searchParams.companyAddressUSPSVerified),
                primaryContactHasEmail: convertAllYesNoToBoolean(searchParams.primaryContactHasEmail),
                primaryContactHasPhone: convertAllYesNoToBoolean(searchParams.primaryContactHasPhone),
                portfolioValue: searchParams.portfolioValue,
                propertiesOwned: searchParams.totalPropertiesOwned,
                squareFootage: searchParams.squareFootage,
                yearBuilt: searchParams.yearBuilt,
                acquisitionDate:  {
                        minDate: searchParams.acquisitionDate.min ? convertDateTimeToDate(searchParams.acquisitionDate.min) : null,
                        maxDate: searchParams.acquisitionDate.max ? convertDateTimeToDate(searchParams.acquisitionDate.max) : null,
                    },
                addressUSPSVerified: convertAllYesNoToBoolean(searchParams.buildingAddressUSPSVerified),
                ownerOccupied: convertAllYesNoToBoolean(searchParams.ownerOccupied),
                sizeAtLocation: searchParams.hailSize,
                maxSizeStormDate:  {
                        minDate: searchParams.maxSizeStormDate.min ? convertDateTimeToDate(searchParams.maxSizeStormDate.min) : null,
                        maxDate: searchParams.maxSizeStormDate.max ? convertDateTimeToDate(searchParams.maxSizeStormDate.max) : null,
                    } ,
                mostRecentStormDate: {
                        minDate: searchParams.mostRecentStormDate.min ? convertDateTimeToDate(searchParams.mostRecentStormDate.min) : null,
                        maxDate: searchParams.mostRecentStormDate.max ? convertDateTimeToDate(searchParams.mostRecentStormDate.max) : null,
                    },
                propertyInSalesforce: convertAllYesNoToBoolean(searchParams.propertyInSalesforce),
                previouslyExported: convertAllYesNoToBoolean(searchParams.previouslyExported),
                dateAddedToDb: {
                    minDate: searchParams.dateAddedToDb.min ? convertDateTimeToDate(searchParams.dateAddedToDb.min) : null,
                    maxDate: searchParams.dateAddedToDb.max ? convertDateTimeToDate(searchParams.dateAddedToDb.max) : null,
                },
                roofResearchComplete: convertAllYesNoToBoolean(searchParams.roofResearchCompleted),
                isVerifiedOwnerProfile : searchParams.isVerifiedOwnerProfile !== null ? searchParams.isVerifiedOwnerProfile : null,
                isVerifiedPrimary : searchParams.isVerifiedPrimary !== null ? searchParams.isVerifiedPrimary : null,
                isVerifiedOwner : searchParams.isVerifiedOwner !== null ? searchParams.isVerifiedOwner : null,
                isAccountBasedResearch : searchParams.isAccountBasedResearch !== null ? searchParams.isAccountBasedResearch : null,
                dataSourceId: parseInt(searchParams.source.id) === 0 ? null : searchParams.source.id,
                idiCoreStatusId: searchParams.idiCoreStatus.id || null,
                includeNonPrimaryOwners: searchParams.includeNonPrimaryOwners !== null ? searchParams.includeNonPrimaryOwners : null
            } : {};

            filter = removeEmpty(filter);
            // The removeEmpty function converts arrays to nested json, so multi-select properties need to be added
            // calling the function. Any future multi-select fields should also be added here as well.
            // (future to-do to rewrite the removeEmpty function  to handle arrays as well)
            if (searchParams.roofType && searchParams.roofType.length > 0) {
                filter = {...filter, roofTypes : searchParams.roofType}
            }
            if (searchParams.buildingType && searchParams.buildingType.length > 0) {
                filter = {...filter, buildingUseCases : searchParams.buildingType}
            }
            
            const payload = {
                "filter": filter,
                "pageSize": params.endRow - params.startRow,
                "pageNumber": (params.startRow / (params.endRow - params.startRow)) + 1
            }

            if (params.sortModel.length > 0) {
                payload.sortField = params.sortModel[0].colId;
                payload.sortDirection = params.sortModel[0].sort;
            }
            else {
                // force default sort on Lead Name if no order requested
                payload.sortField = "leadName";
                payload.sortDirection = "ASC";
            }

            submitLeadSearch(payload).then(response => {
                totalRowRcvr(response?.data?.totalRecordCount);
                params.successCallback(response.data.results, response?.data?.totalRecordCount);
            });
        }
    }
};

